body{
  /* background-image: linear-gradient(#000000, #36f6f3); */
  background-image: linear-gradient(#000000, #000000);
  background-repeat: no-repeat;
  background-size: cover;  
  background-attachment: fixed;
  
  min-height: 100vh;
}
 
.nav{
  
 margin-left: 48%;
 margin-right: 22%;
 font-size: 17px;
 margin-top: 10px;
}
.nav-item{
  text-decoration: none;
 padding: 10px;
}
.nav-item a{
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-weight: bolder;
}
.nav:hover .nav-item{  
 opacity: 0.5;
 filter: blur(2px);
}
.nav .nav-item:hover{ 
  opacity: 1;
  filter: blur(0px);  
  a{
    color: aquamarine;
  }
}
.lang{
  display: none;
  font-weight: bolder; 
  color: rgb(113, 120, 123); 
}
 .logo{ 
   min-height: 80vh; 
   position: fixed; 

 }
.logo-item {
  color:aquamarine;
  font-size: 23px;
  transition: 0.3s;
}
.logo-item:hover{ 
  font-size: 30px; 
}
.logo-item a{
  color:aquamarine;
}
 
 

.theme-checkbox {
  --toggle-size: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 6.25em;
  height: 3.125em;
  background: -o-linear-gradient(left, #efefef 50%, #2a2a2a 50%) no-repeat;
  background: linear-gradient(to right, #efefef 50%, #2a2a2a 50%) no-repeat;
  background-size: 205%;
  background-position: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border-radius: 99em;
  position: relative;
  cursor: pointer;
  font-size: var(--toggle-size);
}

.theme-checkbox::before {
  content: "";
  width: 2.25em;
  height: 2.25em;
  position: absolute;
  top: 0.438em;
  left: 0.438em;
  background: -o-linear-gradient(left, #efefef 50%, #2a2a2a 50%) no-repeat;
  background: linear-gradient(to right, #efefef 50%, #2a2a2a 50%) no-repeat;
  background-size: 205%;
  background-position: 100%;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.theme-checkbox:checked::before {
  left: calc(100% - 2.25em - 0.438em);
  background-position: 0;
}

.theme-checkbox:checked {
  background-position: 100%;
}


  

 
.line__1,
.line__2 {
  color: #ffff;
  white-space: nowrap; /* keep text in one line */
  overflow: hidden; /* hide text behind the cursor */ 
  font-family: Georgia, serif;
  font-size: 2rem;
  font-weight: bold;
}

.line__1 { 

  /* define animation types for text + cursor */
  animation: animated-text__1 1s steps(30, end) 1 normal both,
    animated-cursor__1 110ms steps(30, end) 5;
}

.line__2 {
  /* define animation types for text + cursor */
  color: aquamarine;
  animation: animated-text__2 500ms steps(30, end) 100ms 1 normal both,
    animated-cursor__2 100ms steps(20, end) 5;
  -webkit-animation-delay: 1s;
  animation-delay: 500ms;
}

@keyframes animated-cursor__1 {
  from {
    border-right: 2px solid rgba(17, 17, 17, 0.9);
    border-right-color: rgba(17, 17, 17, 0.9);
  }
  to {
    border-right: 2px solid rgba(17, 17, 17, 0.9);
    border-right-color: rgba(255, 255, 255, 0.8);
  }
}

@keyframes animated-cursor__2 {
  from {
    border-right: 2px solid rgba(17, 17, 17, 0.9);
    border-right-color: rgba(17, 17, 17, 0.9);
  }
  to {
    border-right-color: rgba(255, 255, 255, 0.8);
    border-right: 2px solid rgba(255, 255, 255, 0.9);
  }
}

@keyframes animated-text__1 {
  from {
    width: 0;
  }
  to {
    width: 20rem; 
  }
}

@keyframes animated-text__2 {
  from {
    width: 0;
  }
  to {
    width: 17rem;
  }
}


.button {
  --main-focus: #2d8cf0;
  --font-color: #323232;
  --bg-color-sub: #dedede;
  --bg-color: #eee;
  --main-color: #323232;
  position: relative;
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 10px;
  overflow: hidden;
}

.button, .button__icon, .button__text {
  transition: all 0.3s;
}

.button .button__text {
  transform: translateX(5px);
  color: var(--font-color);
  font-weight: 600;
}

.button .button__icon {
  position: absolute;
  transform: translateX(109px);
  height: 100%;
  width: 39px;
  background-color: var(--bg-color-sub);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button .svg {
  width: 20px;
  fill: var(--main-color);
}

.button:hover {
  background: var(--bg-color);
}

.button:hover .button__text {
  color: transparent;
}

.button:hover .button__icon {
  width: 148px;
  transform: translateX(0);
}

.button:active {
  transform: translate(3px, 3px);
  box-shadow: 0px 0px var(--main-color);
}



 
 
.rotate{
 padding: 20px;
 background-color: #363636;
 height: 600px;
 width: 100%;
 border-radius: 10px; 
 transform: skewY(3deg);
}
.rerotate{
  transform: skewY(-3deg);
}
.box-container{
 height: 400px;
 width: 700px;
 position: relative;
}
.box1{
  height: 70%; 
  width: 40%;  
  position:absolute;
  top: -10%;
  z-index: 9;
  left: -10%;
}
.box2{
  height: 100%;
  background-color: #2a4541;
  padding-top: 20px;
  color: aquamarine; 
  padding-left: 15%;
  border-radius: 10px;
  left: 20%;
  width: 100%;
  box-shadow: 5px 5px 20px 5px  rgb(20, 19, 19);
  position:absolute;
}
 
.img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 3px 3px 10px 3px  rgb(47, 47, 47);
}

.skill{
  padding: 20px;
  background-color: #363636;
  height: 600px;
  width: 80%;
  border-radius: 10px; 
}
.skill-img{
  width: 110px;
  height: 110px;
  background-color: aqua;
  transition: all 0.3s;
}
.skill-img:hover{ 
  opacity: 0.5;
}

.pr-rotate{ 
  background-color: #363636;
  height: 650px; 
  width: 100%;
  border-radius: 10px; 
  transform: skewY(3deg);
 }

 .pro-ig{  
  width: 90%;
  height: 450px;
 }
 .pro-card{
  margin-top: 150px;
  border-radius: 20px;
  padding: 15px;
  color: white;
  margin-left: 50px;
  background-color: #5a5858;
  box-shadow: 1px 1px 10px 2px black;
 }
 .pro-btn{
  text-align: center;
  background-color: transparent;
  color: aquamarine;
  border-radius: 10px;
  padding: 10px 20px;
  border: 2px solid aquamarine;
 }


  
.ft{
  list-style: none;
  font-size: 17px;
  margin-top: 10px;
 }
 .ft-item{
  text-decoration: none;
  display: inline-block;
  padding: 0px 10px;
  border-right: 2px white solid;
 }
 .ft-item a{
   text-decoration: none;
   color: rgb(255, 255, 255);
   font-weight: bolder;
 }
 .ft-item a:hover{
  color: aquamarine;
 }
 .ft-it{
  text-decoration: none;
  display: inline-block;
  padding: 0px 10px; 
 }
 .ft-it a{
   text-decoration: none;
   color: rgb(255, 255, 255);
   font-weight: bolder;
 }
 .ft-it a:hover{
  color: aquamarine;
 }